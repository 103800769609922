import * as SOURCE from "three";
import * as React from "react";
import { useRef, useEffect, useState } from "react";

// Imported NavigationControl - you may not need - map
import { NavigationControl } from "react-map-gl";

import { Interaction } from "three.interaction";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

import {
  setHealth,
  playerStats,
  playerWin,
  updateStatsUi,
  calculatePlayerDamage,
  startSpinAnimation,
  checkHit,
  checkPlayerClick,
  encounterMob,
  encounterRenderer,
  encounterControls,
  encounterCamera,
  setEncounterInfo,
  getEncounterInfo,
  playerDamageIndicate,
} from "./MapQuest";

let playerTurn = true;

export function getPlayerTurn() {
  return playerTurn;
}
export function setPlayerTurn(turn) {
  playerTurn = turn;
}
export function playerHit() {
  if (!playerTurn) return;

  playerTurn = false;

  if (checkHit()) {
    startSpinAnimation("player");
    setEncounterInfo(
      "enemyHealth",
      getEncounterInfo("enemyHealth") - calculatePlayerDamage()
    );
    // encounterInfo.enemyHealth -= calculatePlayerDamage();

    if (getEncounterInfo("enemyHealth") <= 0) {
      startSpinAnimation("player");
      setTimeout(function () {
        playerWin();
      }, 1000);
    }
  }

  updateStatsUi();

  setTimeout(function () {
    startSpinAnimation(encounterMob);

    let playerHealth = playerStats.health;
    playerHealth -= Math.floor(Math.random() * 10);
    setHealth(playerHealth);
    playerTurn = true;
    playerDamageIndicate();
  }, 1000);
}

export function Encounter() {
  React.useEffect(() => {
    var encounterCanvas = document.getElementById("encounterCanvas");

    encounterCanvas.addEventListener("click", function (e) {
      if (checkPlayerClick()) {
        // alert("HELLO");
        playerHit();
      }
    });
  });

  return (
    <encounter-container id="encounter">
      <video id="userVideo"></video>
      <canvas id="encounterCanvas"></canvas>

      <encounter-ui>
        <encounter-btn id="leave-encounter">Leave</encounter-btn>
        <encounter-reticle></encounter-reticle>
        <encounter-hit-area id="hit-area">
          <encounter-hit-bar id="hit-bar"></encounter-hit-bar>
        </encounter-hit-area>
      </encounter-ui>
      <player-info>
        <health-container id="healthInfo"></health-container>
        <health-container id="enemyHealth"></health-container>
      </player-info>
    </encounter-container>
  );
}
