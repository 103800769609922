import React from "react";

// Icons/images
import Icons from "../../img/Icons";

export const HomePageCard = (props) => {
  return (
    <div
      className="card-background"
      style={{ backgroundImage: `url(${Icons.iconBackground})` }}
    >
      <div>
        <div className="Card__text-container">
          <div className="Card__logo">
            <img src={Icons.iconSQLogo} alt="Game logo" />
          </div>

          <h2 className="Card__text-gameName">{props.GameName}</h2>
          <p className="Card__text-SubTitle">{props.SubTitle}</p>
        </div>
      </div>
    </div>
  );
};
