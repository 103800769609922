// import icons from icons
import Icons from "../../img/Icons";

export const AccordionData = [
  {
    title: "What is the game about?",
    content: ` Street Quest is a game designed to encourage play, walking, social interaction and exploration of built environment, creating stories about heritage, belonging, and place from the creators`,
  },
  {
    title: "Why Walk  ",
    content: (
      <ul className="content-text">
        <li>Reduces stress and anxiety</li>
        <li>Boosts mood and alleviates depression</li>
        <li>Improves sleep quality</li>
        <li>Enhances cognitive function and memory</li>
        <li>Provides a sense of accomplishment and routine</li>
      </ul>
    ),
  },
  {
    title: "How does it work?",

    content: (
      <ol className="content-text">
        <li>Open Map - Video tto be included here</li>
        <li>Look around and choose an object you want to interact with</li>
        <li>Walk to its location - decide what route to take</li>
        <li>
          When you are near the object, an "engage" button will appear on the
          screen
        </li>
        <li>
          Point your phone at the moving object, try to line it up to the exact
          center, and press your player
        </li>
        <li>
          If you are successful, your player will spin, and the enemy hitpoints
          will decrease
        </li>
        <li>
          Watch your own hitpoints too! The enemy will affect you every time you
          try to engage with it
        </li>
        <li>
          If you are very successful, the enemy levels will drop to below 0, and
          you have finished the encounter
        </li>
        <li>
          If you are missing the alignments, think about what change to your
          strategy might help
        </li>
        <li>
          To repeat, walk to find other creatures and objects and interact with
          them the same way
        </li>
        <li>Notice how they move differently</li>
      </ol>
    ),
  },
  {
    title: "Contributors ",
    content: (
      <ul className="content-text">
        <li>Angela W.</li>
        <li>Annie P.</li>
        <li>Charlotte S.</li>
        <li>Connor H.</li>
        <li>Dan C.</li>
        <li>Duru O.</li>
        <li>Evan O.</li>
        <li>Finn A.</li>
        <li>Jessica J.</li>
        <li>Sophie E.</li>
        <li>
          We would like to keep your name one the list - please finish your
          icon, if you have not had a chance to so far, and send it through to
          us at arhiveorg@gmail.com
        </li>
      </ul>
    ),
  },
  {
    title: "Who are the developers",
    content: (
      <div className="content-text">
        <div className="accordion_logo">
          <img src={Icons.iconLogo} alt="Logo" className="logo" />
          <a
            className="content-web-link"
            href="https://arhive.co"
            target="_blank"
            rel="noopener noreferrer"
          >
            AR Hive
          </a>
        </div>
        Make Augmented reality games and virtual reality experiences which
        interact with the real-world. Headed by CEO and Founder Judith Ricketts,
        an Artist, frontend developer, Senior lecturer in Games Art.
      </div>
    ),
  },
  {
    title: "How to get involved ",

    content: (
      <div className="content-text">
        If your school or college would like to get involved get in touch with
        us at{" "}
        <a className="content-email" href="mailto:arhiveorg@gmail.com">
          Email arhiveorg@gmail.com
        </a>
      </div>
    ),
  },

  {
    title: "Where to get help",
    content: (
      <ul className="content-text">
        <li>
          <p>
            <strong> Childline - 24/7: </strong> If you're under 19 you can
            confidentially call, chat online or email about any problem big or
            small.
          </p>
          <p>Contact: 0800 11 11</p>
        </li>
        <br></br>
        <li>
          <p>
            <strong>The Mix - 24/7 </strong>
            Support service for young people
          </p>
          <p>Contact: Text THEMIX to 85258</p>
        </li>
        <br></br>
        <li>
          <p>
            <strong>
              NHS Urgent Mental Health Helpline (England only) - 24/7
            </strong>{" "}
            Offers mental health support and advice, help to speak to a mental
            health professional, and can arrange an assessment to help decide on
            the best course of care.
          </p>
          <p> Contact: 111 </p>
        </li>
        <br></br>
        <li>
          <p>
            <strong>Samaritans - 24/7</strong>
            Whatever you're going through, you can contact the Samaritans for
            support. N.B. This is a listening service and does not offer advice
            or intervention.
          </p>
          <p>
            Contact: 116123 or{" "}
            <a className="content-email" href="mailto:jo@samaritans.org">
              Email Jo@samaritans.org
            </a>
          </p>
        </li>
        <br></br>
        <li>
          <p>
            <strong>Shout - 24/7</strong>
            Shout provides free support for young people across the UK
            experiencing a mental health crisis.
          </p>
          <p>Contact: Text SHOUT to 85258.</p>
        </li>
        <br></br>
        <li>
          <h3>Papyrus UK - 24/7</h3>
          <p>
            Offers confidential advice and support if you're struggling with
            suicidal thoughts, and information about how to make a safety plan.
          </p>
          <p>
            Contact: 0800 068 4141, Text: 88247 or{" "}
            <a className="content-email" href="mailto:pat@papyrus-uk.org">
              Email pat@papyrus-uk.org
            </a>
          </p>
        </li>
      </ul>
    ),
  },
];
