import * as React from "react";
import { HomePageCard } from "./HomePageCard";
import WeatherComponent from "../Weather/Weather"; // Import the weather component

import "./Home.css";

export const Home = () => {
  return (
    <div className="cardP">
      <HomePageCard
        GameName=<h1 className="Home_header">Street Quest</h1>
        SubTitle=<h2 className="tradeMark"> TM</h2>
      />
      <WeatherComponent />
    </div>
  );
};
