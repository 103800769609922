import React, { useState } from "react";
import "./SignInSignUp.css";
import Icons from "../../img/Icons";
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import { updateNav } from "../../Navigation/Navbar";
// import Router from "router";
import axios from "axios";
import { setCookies, getCookies } from "../MapQuest/MapQuest";
// import express from "express";
import { playerStats } from "../MapQuest/MapQuest";
import { useEffect } from "react";
import { loadSteps } from "../MapQuest/Pedometer";
import {
  getDatabase,
  ref,
  set,
  update,
  get,
  onValue,
  remove,
} from "firebase/database";
import { loadInventory } from "../MapQuest/Inventory";

const firebaseConfig = {
  apiKey: "AIzaSyDoyyOKhfOx6RMrud3l877PelAxTjWvpJU",
  authDomain: "street-quest-11545.firebaseapp.com",
  databaseURL:
    "https://street-quest-11545-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "street-quest-11545",
  storageBucket: "street-quest-11545.appspot.com",
  messagingSenderId: "489751953754",
  appId: "1:489751953754:web:b1f063e74f0ae00c607363",
  measurementId: "G-35BC1H35SN",
};

// Router.get("/auth/discord/login", async (ctx) => {
//   const url =
//     "https://discord.com/oauth2/authorize?client_id=1268157097569419324&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A4000%2Fauth%2Fdiscord%2Fcallback&scope=identify";

//   ctx.redirect(url);
// });

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const googleAuth = getAuth();
const provider = new GoogleAuthProvider();
let userInfo = undefined;

console.log("CODE:", process.env);

let discordCallbackString = "/auth/discord/callback";

console.log("COOKIES LOAD: ", getCookies(document.cookie));

export function getSignedIn() {
  let cookies = getCookies(document.cookie);

  if (cookies.signedIn && cookies.signedIn != "false") {
    playerStats.login = true;
  } else {
    playerStats.login = false;
  }

  return playerStats.login;
}

async function checkDiscordCallback() {
  let pathName = window.location.pathname;
  console.log("PATH : ", pathName);
  if (pathName == discordCallbackString) {
    console.log("IN DISCORD LOGIN");

    alert("HELLO");

    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);

    console.log(" GETTING CODE", urlParams.get("code"));

    if (urlParams.get("code")) {
      alert("HELLO 2");
      console.log("IN CODE");
      const discordCode = urlParams.get("code");

      console.log(
        "IN CODE INFO,",
        process.env.REACT_APP_DISCORD_CLIENT_ID,
        process.env.REACT_APP_DISCORD_CLIENT_SECRET,
        process.env.REACT_APP_DISCORD_REDIRECT_URI
      );

      const params = new URLSearchParams({
        grant_type: "authorization_code",
        discordCode,
        redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
      });

      console.log("IN CODE 3", params);
      console.log(`IN CODE |${discordCode}|`);
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        // "Accept-Encoding": "application/x-www-form-urlencoded",
      };

      console.log("IN CODE 4");

      // const response = await fetch("https://discord.com/api/v10/oauth2/token", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //     "accept-encoding": "application/x-www-form-urlencoded",
      //   },
      //   body: new URLSearchParams({
      //     client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
      //     client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
      //     grant_type: "authorization_code",
      //     code: discordCode,
      //     redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
      //   }),
      // });

      alert("HELLO 3");

      alert(process.env.REACT_APP_DISCORD_CLIENT_ID);
      alert(process.env.REACT_APP_DISCORD_CLIENT_SECRET);
      alert(process.env.REACT_APP_DISCORD_REDIRECT_URI);
      alert(discordCode);
      let options = {
        url: "https://discord.com/api/oauth2/token",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
          client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
          grant_type: "client_credentials",
          code: discordCode,
          redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
          scope: "identify email",
        }),
      };

      alert("HELLO 3.5");
      let response = await fetch(
        "https://discord.com/api/oauth2/token",
        options
      )
        .then((data) => {
          alert("HELLO 3.6");
          return data.json();
        })
        .then((data) => {
          alert("HELLO 3.7");
          return data;
        });

      alert("HELLO 4");

      // // request.post())
      // const response = await axios.post(
      //   "https://discord.com/api/v10/oauth2/token",
      //   params,
      //   {
      //     headers,
      //   },

      //   (process.env.REACT_APP_DISCORD_CLIENT_ID,
      //   process.env.REACT_APP_DISCORD_CLIENT_SECRET)
      // );
      console.log("IN CODE 5");

      console.log("RESPONSE", response);

      const userResponse = await axios.get(
        "https://discord.com/api/users/@me",
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        }
      );

      alert("HELLO 5");
      document.cookie = `discToken=${response.access_token}; 18 Dec 2050 12:00:00 UTC; path=/`;
      alert("SET COOKIE");
      const { id, username, avatar } = userResponse.data;

      console.log("USER INFO ", id, username, avatar);
      setUserSignIn(id, username, "discord");
    }

    console.log("COOKIES AFTER NEW: ", getCookies(document.cookie));
    alert("AFTER");
    window.location.replace("/SignInSignUp  ");
  }
}

function setUserSignIn(
  id,
  name,
  type,
  accessToken = "null",
  refreshToken = "null"
) {
  playerStats.id = id;
  playerStats.name = name;
  playerStats.loginType = type;
  playerStats.login = true;

  loadInventory();
  setCookies({
    signedIn: type,
    accessToken: accessToken,
    refreshToken: refreshToken,
    id: playerStats.id,
    name: playerStats.name,
  });
}

function getUserSignedIn() {
  let info = getCookies(document.cookie);

  if (info.signedIn && info.signedIn != "false") {
    playerStats.id = info.id;
    playerStats.name = info.name;
    playerStats.loginType = info.signedIn;
    playerStats.login = true;
  } else {
    playerStats.login = false;
    playerStats.id = undefined;
    playerStats.name = undefined;
    playerStats.loginType = undefined;
  }
}

export function loadPageSignUp() {
  console.log("LOADING PAGE SIGN UP");
  getUserSignedIn();

  console.log("LOADTAG: ", playerStats.loginType, playerStats.id);
  loadInventory();
  loadSteps();

  let nowSignedIn = getSignedIn();
  updateNav(nowSignedIn);

  // alert("NOW SIGNED IN ");
  // alert(nowSignedIn);
  if (!nowSignedIn) {
    let thisHref = window.location.href;

    if (
      !thisHref.includes("SignInSignUp") &&
      !thisHref.includes("mapQuest") &&
      !thisHref.includes("auth/discord/callback") &&
      !thisHref.includes("disallowed")
    ) {
      alert("REROUTING");
      window.location.replace("/SignInSignUp  ");
    }
  }
}

onAuthStateChanged(googleAuth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    console.log("SIGNED IN,", user);

    setUserSignIn(user.uid, user.displayName, "google");
    userInfo = user;
  } else {
    // setSignIn(false);
    console.log("NOT SIGNED IN,", user);
    // User is signed out
    userInfo = undefined;

    let thisHref = !window.location.href;
    if (
      !thisHref.includes("mapQuest") &&
      !thisHref.includes("auth/discord/callback")
    ) {
      alert("REROUTING HERE");
      window.location.replace("/SignInSignUp  ");
    }

    // ...
  }
});

getRedirectResult(googleAuth)
  .then((result) => {
    alert("HELL2O");
    // This gives you a Google Access Token. You can use it to access Google APIs.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;

    // The signed-in user info.
    const user = result.user;
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  })
  .catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });

function googleSignIn() {
  alert("CLICK");
  signInWithRedirect(googleAuth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)

      console.log("SIGNED IN WITH POPUP");
      setUserSignIn(user.uid, user.displayName, "google");
      userInfo = result.user;
      console.log(result);
      // ...
      // window.location.replace("/SignInSignUp  ");
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}

console.log("PLAYER LOGIN ", playerStats.login);
checkDiscordCallback();
export const SignInSignUp = () => {
  const [isSignUp, setIsSignUp] = useState(false);

  const [signedIn, setSignIn] = useState(false);

  function signUserOut() {
    playerStats.login = false;

    if (playerStats.loginType == "google") {
      signOut(googleAuth)
        .then(() => {
          setCookies({ signedIn: "false" });
          setSignIn(false);
          window.location.replace("/SignInSignUp  ");
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
    } else if (playerStats.loginType == "discord") {
      alert("SIGNED OUT NOW");
      setCookies({ signedIn: "false" });
      setSignIn(false);
      window.location.replace("/SignInSignUp  ");
    }
  }

  // const [countryCodes, setCountryCodes] = useState([]);
  // const [months, setMonths] = useState([]);
  // const [years, setYears] = useState([]);

  // const populateCountryCodes = () => {
  //   const codes = [
  //     { code: "+1", country: "United States" },
  //     { code: "+44", country: "United Kingdom" },
  //     // Add more country codes as needed
  //   ];
  //   setCountryCodes(codes);

  //   // Set the default country code based on user's location
  //   const userCountry = "United States"; // Replace with code to detect user's country
  //   const defaultCountryCode =
  //     codes.find(({ country }) => country === userCountry)?.code || "+1";
  //   // Set the default country code in the state or update the select element
  // };

  // const populateMonthAndYear = () => {
  //   const currentYear = new Date().getFullYear();
  //   const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1);
  //   const yearOptions = Array.from(
  //     { length: 101 },
  //     (_, i) => currentYear - 100 + i
  //   );
  //   setMonths(monthOptions);
  //   setYears(yearOptions);
  // };

  //   const googleBtns = document.getElementsByClassName("google-btn");

  //   console.log("BTNS",googleBtns)
  //   for(var i = 0; i < googleBtns.length; i++){
  //     googleBtns[i].onclick = function(){ alert("HELLO"); googleSignIn(); };
  //  }

  const showSignInForm = () => {
    setIsSignUp(false);
  };

  const toggleForms = () => {
    setIsSignUp((prevState) => !prevState);
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();

    // Get the form input values
    const firstName = document.getElementById("signInFirstName").value;
    const lastName = document.getElementById("signInLastName").value;
    const password = document.getElementById("signInPassword").value;

    try {
      // Send a request to the server for authentication
      const response = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstName, lastName, password }),
      });

      // Handle the server response
      if (response.ok) {
        // Authentication successful
        const data = await response.json();
        // Store the authentication token or session information
        localStorage.setItem("authToken", data.token);
        // Redirect the user to a protected route or dashboard
        window.location.href = "/dashboard";
      } else {
        // Authentication failed
        const error = await response.text();
        console.error("Login failed:", error);
        // Display an error message to the user
        alert("Login failed. Please check your credentials and try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Display an error message to the user
      alert("An error occurred. Please try again later.");
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();

    // Get the form input values
    const firstName = document.getElementById("signUpFirstName").value;
    const lastName = document.getElementById("signUpLastName").value;
    const email = document.getElementById("signUpEmail").value;
    const phone = document.getElementById("signUpPhone").value;
    const password = document.getElementById("signUpPassword").value;
    const confirmPassword = document.getElementById(
      "signUpConfirmPassword"
    ).value;

    // Validate the form inputs
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      // Send a request to the server to create a new user account
      const response = await fetch("/api/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstName, lastName, email, phone, password }),
      });

      // Handle the server response
      if (response.ok) {
        // Sign-up successful
        alert("Sign-up successful! You can now log in.");
        // Redirect the user to the login page or perform additional actions
      } else {
        // Sign-up failed
        const error = await response.text();
        console.error("Sign-up failed:", error);
        alert("Sign-up failed. Please try again later.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  function discordSignIn() {
    window.location.replace(
      "https://discord.com/oauth2/authorize?client_id=1268157097569419324&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth%2Fdiscord%2Fcallback&scope=identify+email"
    );
  }

  return (
    <div
      className="card-background"
      style={{ backgroundImage: `url(${Icons.iconBackground})` }}
    >
      {!getSignedIn() ? (
        <div className="container">
          <div className="toggle-container">
            <div className="form-container">
              <div className="slider">
                <span className="slider-text">Sign In</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    id="toggle"
                    checked={isSignUp}
                    onChange={toggleForms}
                  />
                  <span className="slider-toggle round"></span>
                </label>
                <span className="slider-text">Sign Up</span>
              </div>
              {!isSignUp ? (
                /* Sign-in form ----------------------------------------------------------- */

                <form-signin
                  className="sign-in-form"
                  onSubmit={handleSignInSubmit}
                >
                  <h1 className="signIn_header-text">Sign In</h1>
                  <div className="form-group">
                    <input
                      type="text"
                      id="signInFirstName"
                      placeholder="First Name"
                      pattern="[A-Za-z]+"
                      required
                    />
                    <input
                      type="text"
                      id="signInLastName"
                      placeholder="Last Name"
                      pattern="[A-Za-z]+"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      id="signInPassword"
                      placeholder="Password"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input type="checkbox" className="rememberMe_label" />
                    <label htmlFor="rememberMe">Remember Me &nbsp; </label>
                    {/* update this--------------------------------------------------- */}
                    <a
                      href="#"
                      className="forgotPassword_link"
                      // id="forgotPassword"
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <button className="signIn_button" type="submit">
                    Log In
                  </button>
                  <div className="social-login">
                    <button
                      onClick={googleSignIn}
                      className="social-btn google-btn"
                    >
                      <img
                        className="google-logo"
                        src={Icons.iconGoogle}
                        alt="Google Logo"
                      />
                    </button>

                    {/* <button className="social-btn apple-btn">Apple login</button>
                  <button className="social-btn facebook-btn">
                    Facebook login
                  </button> */}
                  </div>
                  <div
                    className="social-login discord-login"
                    onClick={discordSignIn}
                    id="discord"
                  >
                    <img
                      class="discord-logo"
                      src={Icons.iconDiscord}
                      alt="Discord Logo"
                    />
                    Sign in with discord
                  </div>
                </form-signin>
              ) : (
                /* Sign-up form ----------------------------------------------------------- */

                <form-signup
                  className="sign-up-form"
                  onSubmit={handleSignUpSubmit}
                >
                  <h2 className="signUp_header-text">Sign Up</h2>
                  <div className="form-group">
                    <input
                      type="text"
                      id="signUpFirstName"
                      placeholder="First Name"
                      pattern="[A-Za-z]+"
                      required
                    />
                    <input
                      type="text"
                      id="signUpLastName"
                      placeholder="Last Name"
                      pattern="[A-Za-z]+"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      id="signUpEmail"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="tel"
                      id="signUpPhone"
                      placeholder="Phone Number"
                      required
                    />
                    {/* <select id="countryCode">
                    {countryCodes.map(({ code, country }) => (
                      <option
                        key={code}
                        value={code}
                      >{`${code} (${country})`}</option>
                    ))}
                  </select> */}
                  </div>
                  {/* <div className="form-group">
                  <select id="signUpMonth" required>
                    <option value="">Month</option>
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <select id="signUpYear" required>
                    <option value="">Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div> */}
                  <div className="form-group">
                    <input
                      type="password"
                      id="signUpPassword"
                      placeholder="Password"
                      required
                    />
                    <input
                      type="password"
                      id="signUpConfirmPassword"
                      placeholder="Confirm Password"
                      required
                    />
                  </div>

                  <div className="social-login">
                    <button
                      onClick={googleSignIn}
                      className="social-btn google-btn"
                    >
                      <img
                        className="google-logo"
                        src={Icons.iconGoogle}
                        alt="Google Logo"
                      />
                    </button>

                    {/* <button className="social-btn apple-btn">Apple login</button>
                  <button className="social-btn facebook-btn">
                    Facebook login
                  </button> */}
                  </div>

                  <button className="signUp_button" type="submit">
                    Sign Up
                  </button>
                </form-signup>
              )}
            </div>
          </div>
        </div>
      ) : (
        <sign-out-temp onClick={signUserOut} id="signOut">
          Sign out
        </sign-out-temp>
      )}
    </div>
  );
};

export default SignInSignUp;
