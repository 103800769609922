import "./App.css";

// Page/ navigation Routing
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navigation/Navbar";
import { Home } from "./components/Pages/Home/Home";
import { About } from "./components/Pages/About/About";
// import { Developer } from "./components/Pages/Developer/Developer";
import { MapQuest } from "./components/Pages/MapQuest/MapQuest";
import { SignInSignUp } from "./components/Pages/SignInSignUp/SignInSignUp";
// import { Projects } from "./components/Pages/Projects/Projects";
import { Gallery } from "./components/Pages/Gallery/Gallery";
import { Contact } from "./components/Pages/Contact/Contact";
import { SharedLayout } from "./components/Navigation/SharedLayout";
import { Error } from "./components/Pages/Error/Error";

function checkMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

let persistantPass = "";
async function checkAllowed() {
  console.log("CHECK MOBILE " + checkMobile() + " " + navigator.userAgent);

  if (window.location.href.includes("disallowed")) return;

  if (!checkMobile()) {
    window.location.replace("/disallowed?message=noMobile");
    return;
  }
}

checkAllowed();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* index is parent and content for the index using outlet to render the results */}
        {/* <Route index element={<Home />} /> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        {/* <Route exact path="/developer" element={<Developer />} /> */}
        <Route exact path="/mapQuest" element={<MapQuest />} />
        <Route exact path="/SignInSignUp" element={<SignInSignUp />} />
        {/* <Route exact path="/locations" element={<Locations />} /> */}
        {/* <Route exact path="/projects" element={<Projects />} /> */}
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/" element={<SharedLayout />} />
        <Route exact path="*" element={<Error />} />
      </Routes>

      {/* position navigation  */}
      <Navbar />
    </BrowserRouter>
  );
}

export default App;
