import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../img/Nav_Icons/logo.png";
import "./Error.css";

export const Error = () => {

  let userDisallowed = window.location.href.includes("disallowed")

  let disallowedMsg = null;
  if (userDisallowed){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const message = urlParams.get('message')
    if (message == "noMobile"){
      disallowedMsg = "This application is only available on mobile"
    }else if (message == "noPasscode"){
      disallowedMsg = "Incorrect User passcode"
    }

  
  }


  return (
    <main className="main__body-error">
      <header className="main__error-header">
        <div className="card__logo-error">
          <img src={Logo} alt="John Hawkins (Hawkyns) Crest" />
        </div>
        <h2 className="main__header-text"> 404</h2>
          <p className="main__body-text" >{disallowedMsg != null ? disallowedMsg:"Page not found" } </p>
      </header>

      <Link className="main__error-link" to="/">
        back home
      </Link>
    </main>
  );
};
